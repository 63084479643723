import { SettingOutlined } from '@ant-design/icons'
import { App, Button, Col, Row, Select, Alert } from 'antd'
import GroupCategoryButton from 'components/GroupCategoryButton'
import LoadingProgress from 'components/LoadingProgress/LoadingProgress'
import useStore from 'hooks/useStore'
import useSubscription from 'hooks/useSubscription'
import useTimeBlockingMonitor from 'hooks/useTimeBlockingMonitor'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TaskGroup } from 'types/Tasks'
import { TimeBlocking } from 'types/TimeBlocking'
import { hexToRgb } from 'utils/taskUtils'
import AccountFilter from './AccountFilter/AccountFilter'
import styles from './AccountGroupFilter.module.scss'
import AddGroupModal from './AddGroupModal'
import GroupFilter from './GroupFilter/GroupFilter'

type AccountGroupFilterProps = {
  label: string
}

const AccountGroupFilter = ({ label }: AccountGroupFilterProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<number | undefined>(
    undefined,
  )
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false)
  const {
    categories,
    getGroupCategory,
    getSubscriptionPackage,
    getAllTaskRolePermission,
    getTimeBlocking,
    getUserConfig,
    getUserGroups,
    selectedCategory,
    selectedGroups,
    setCurrentCategory,
    setSelectedGroups,
    tasks,
    timeBlocking,
  } = useStore((state) => state)
  const user = useStore((state) => state.user?.data)
  const email = user?.email || ''
  const app = App.useApp()
  const { groups } = useStore((state) => state.tasks)
  const { updateSubscriptionPackage } = useSubscription(
    (state) => state,
  )
  const [currentBlock, setCurrentBlock] = useState<TimeBlocking | null>(null)

  useEffect(() => {
    getGroupCategory()
  }, [getGroupCategory])

  useEffect(() => {
    getAllTaskRolePermission()
  }, [getAllTaskRolePermission])

  // TODO: Implement filter by dates in endpoint and use it for filtering records for today.
  useEffect(() => {
    getTimeBlocking()
  }, [getTimeBlocking])

  useEffect(() => {
    getSubscriptionPackage().then((data) => {
      const { package: pkg } = data
      const snake_case_pkg = pkg
        .toLowerCase() // Converts the string to lowercase
        .replace(/\s+/g, '_') // Replaces spaces with _

      updateSubscriptionPackage(snake_case_pkg)
    })
  }, [getSubscriptionPackage, updateSubscriptionPackage])

  useEffect(() => {
    setLoading(true)
    getUserGroups()
      .then((data: TaskGroup[]) => {
        getUserConfig().then((value) => {
          if (value?.preferences?.selectedGroups) {
            setSelectedGroups(value.preferences.selectedGroups, true)
          } else {
            setSelectedGroups(
              data
                .filter(
                  (group) =>
                    group.groupMembers?.find((gm) => gm.user === user?.id)
                      ?.status !== 'inactive' && group.status !== 'archived',
                )
                .map((group) => group.id),
            )
          }
        })
      })
      .catch((error) => {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    setLoading,
    getUserConfig,
    getUserGroups,
    setSelectedGroups,
    app.notification,
    user?.id,
  ])

  const showTimeBlockMessage = useMemo(() => {
    // If there's no current block, don't show message
    if (!currentBlock) {
      return false
    }

    // If current block has a groupCategory but no groups, don't show message
    if (currentBlock.groupCategory && !currentBlock.group?.length) {
      // Check if category has changed
      const hasCategoryChanged =
        currentBlock.groupCategory?.id != null &&
        currentBlock.groupCategory.id !== selectedCategory

      if (hasCategoryChanged) {
        return true
      }
    }

    // Check if any selected groups have changed
    const blockGroups = currentBlock.group
    if (!blockGroups?.length) {
      return false
    }

    const blockGroupIds = blockGroups.map((g) => g.id)

    // Check if arrays have same length and same elements (order doesn't matter)
    const areGroupsEqual =
      blockGroupIds.length === selectedGroups.length &&
      blockGroupIds.every((id) => selectedGroups.includes(id)) &&
      selectedGroups.every((id) => blockGroupIds.includes(id))
    if (areGroupsEqual) {
      return false
    } else {
      return true
    }
  }, [currentBlock, selectedGroups, selectedCategory])

  const timeBlockingChange = (value: TimeBlocking | null) => {
    setCurrentBlock(value)
  }

  useTimeBlockingMonitor({
    timeBlockingList: timeBlocking,
    onTriggerEvent: timeBlockingChange,
    intervalInSeconds: 1,
  })

  const selectAllOption = useMemo(() => {
    return {
      label: <span>{t('my-today-page.select-all')}</span>,
      value: -2,
      title: t('my-today-page.select-all'),
    }
  }, [t])

  const unselectAllOption = useMemo(() => {
    return {
      label: <span>{t('my-today-page.unselect-all')}</span>,
      value: -1,
      title: t('my-today-page.unselect-all'),
    }
  }, [t])

  const activateBlockingMode = useCallback(() => {
    if (currentBlock?.groupCategory?.id && currentBlock?.group?.length > 0) {
      setSelectedGroups(currentBlock.group.map((g) => g.id))
      setCurrentCategory(currentBlock.groupCategory.id)
      setSelectedOption(currentBlock.groupCategory.id)
      return
    }

    if (currentBlock?.groupCategory?.id) {
      categorySelectionChanged(currentBlock?.groupCategory?.id || 0)
      return
    }

    if (currentBlock?.group && currentBlock?.group.length > 0) {
      setSelectedGroups(currentBlock?.group.map((g) => g.id))
      setCurrentCategory(0)
      setSelectedOption(undefined)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBlock])

  const categorySelectionChanged = useCallback(
    (selectedValue: number) => {
      setSelectedOption(selectedValue)
      if (selectedValue === selectAllOption.value) {
        setCurrentCategory(0)
        if (tasks.groups?.length) {
          setSelectedGroups(
            tasks.groups
              .filter((group) => {
                return (
                  group.groupMembers?.find((gm) => gm.user === user?.id)
                    ?.status !== 'inactive' && group.status !== 'archived'
                )
              })
              .map((group) => group.id),
          )
        }
      } else if (selectedValue === unselectAllOption.value) {
        setCurrentCategory(0)
        setSelectedGroups([])
      } else {
        setCurrentCategory(selectedValue)
        const category = categories.find((cat) => cat.id === selectedValue)
        if (category) {
          setSelectedGroups(category.categoryGroups || [])
        }
      }
    },
    [
      categories,
      selectAllOption.value,
      setCurrentCategory,
      setSelectedGroups,
      tasks.groups,
      unselectAllOption.value,
      user?.id,
    ],
  )

  const categoryOptionList = useMemo(() => {
    const options = categories.map((category) => {
      return {
        label: <GroupCategoryButton data={category} />,
        value: category.id,
        title: category.title,
      }
    })
    return [selectAllOption, unselectAllOption, ...options]
  }, [categories, selectAllOption, unselectAllOption])

  const style = useMemo(() => {
    if (selectedCategory) {
      const category = categories.find((cat) => cat.id === selectedCategory)
      if (category?.metadata?.color) {
        const rgb = hexToRgb(category.metadata.color)
        if (rgb) {
          return { backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)` }
        }
      }
    }
    // If there's a current block with a group, use the group color
    if (currentBlock?.group && selectedGroups.length > 0 && groups) {
      const group = groups.find((g) => g?.id === currentBlock?.group[0]?.id)
      if (group && group?.metadata?.color) {
        const rgb = hexToRgb(group.metadata.color)
        if (rgb) {
          return { backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)` }
        }
      }
    }
    return {}
  }, [categories, selectedCategory, groups, selectedGroups, currentBlock])

  const changeFilterStatus = useCallback(
    (id: number) => {
      const currentFilterIndex = selectedGroups.indexOf(id)
      const newFilters =
        currentFilterIndex > -1
          ? selectedGroups.filter((filter) => filter !== id)
          : selectedGroups.concat(id)
      setSelectedGroups(newFilters)
      if (currentBlock?.groupCategory?.id) {
        setSelectedOption(currentBlock?.groupCategory?.id)
      }
      setCurrentCategory(0)
    },
    [selectedGroups, setCurrentCategory, setSelectedGroups, currentBlock],
  )

  if (loading) {
    return <LoadingProgress />
  }

  return (
    <>
      <Row
        wrap={false}
        justify="space-between"
        className={styles.filtersContainer}
        style={style}
      >
        <Col flex="1 1 0px">
          <Row wrap={false}>
            <Col>
              <AccountFilter label={email} />
            </Col>
            <Col flex={1}>
              <GroupFilter
                filters={
                  tasks?.groups?.filter(
                    (group) =>
                      group.groupMembers?.find((gm) => gm.user === user?.id)
                        ?.status !== 'inactive' && group.status !== 'archived',
                  ) || []
                }
                selected={selectedGroups}
                onChange={changeFilterStatus}
              />
            </Col>
          </Row>
        </Col>
        <Col flex="0 1 auto">
          <Row justify="space-between">
            <Col>
              <Select
                style={{ width: '200px' }}
                placeholder={t('settings.categories.select-category')}
                value={selectedCategory || selectedOption || null}
                onChange={categorySelectionChanged}
                options={categoryOptionList || []}
                labelRender={(option) => {
                  if ('title' in option) {
                    return <span>{option.title as string}</span>
                  } else {
                    return <span>{option.label}</span>
                  }
                }}
              />
            </Col>
            <Col>
              <Link to="/settings?activeTab=groups">
                <Button
                  icon={<SettingOutlined />}
                  size="small"
                  type="text"
                ></Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <AddGroupModal
          isOpen={isAddGroupModalOpen}
          handleClose={() => setIsAddGroupModalOpen(false)}
        />
      </Row>
      {showTimeBlockMessage && (
        <Alert
          style={{
            backgroundColor: 'var(--licensing-met-color)',
            color: 'var(--white)',
            fontWeight: 'bold',
            marginTop: 8,
          }}
          message={
            <Trans
              i18nKey={
                currentBlock?.groupCategory?.id
                  ? 'time-blocking.banner-message-category'
                  : 'time-blocking.banner-message-group'
              }
              values={{
                title:
                  currentBlock?.groupCategory?.title ||
                  currentBlock?.group?.[0]?.title,
              }}
              components={{ b: <strong /> }}
            />
          }
          action={
            <Button
              size="small"
              type="text"
              style={{ color: 'var(--white)' }}
              onClick={activateBlockingMode}
            >
              {t('time-blocking.blocking-mode')}
            </Button>
          }
        />
      )}
    </>
  )
}

export default AccountGroupFilter
