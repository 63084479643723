import { Draft, produce, applyPatches, Patch } from 'immer'
import type { StateCreator } from 'zustand'
import {
  CompletionReportType,
  DelegationType,
  TaskItem,
  TaskLevelUser,
  TaskPriorityGroupGlobal,
  TaskStatus,
  Task,
  TaskRecurrence,
  TaskLevel,
  Group,
  TaskGroup,
  TaskPriorityGroupUser,
  TaskStatusId,
  ScheduledTask,
  TaskPriorityGroup,
  SubscriptionStatus,
  CurrentStatus,
  Subscription,
  SubscriptionEvents,
  CustomDataReportType,
  TaskDetails,
} from 'types/Tasks'
import { UserPreferences } from 'types/Settings'
import { UserConfigRoutineTimes } from 'types/Settings'
import { Company, Contact } from 'types/Contacts'
import {
  getGroupsWithAlternativeNames,
  divideTasksByPeriod,
  buildFilterQuery,
  getPriorityGroupIdAndTaskIndex,
  taskShouldBePromoted,
  sortTasksByASCIICode,
} from 'utils/taskUtils'
import { AxiosResponse } from 'axios'
import { ApiState } from './Auth.slice'
import dayjs from 'dayjs'
import {
  filterTasksByFlexibleTime,
  parseScheduleToUpdatable,
} from 'utils/routines'
import { camelize, decamelize } from 'humps'
import { CustomField, CustomFieldValue } from 'types/CustomFields'
import { getTimeZone } from 'utils/IntlUtlis'
import { arraysAreDifferent } from 'utils/settings'

export type ListOption = {
  label: string
  value: number
}

export enum TaskMode {
  DRAFT = 'draft',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  TEMPLATE = 'template',
}

export type TaskType = {
  id?: string
  title?: string
  level?: number
  taskGroup?: number
  group?: number
  priority?: number
  priorityGroup?: number
  parent?: string
  mode?: TaskMode
  children?: Array<TaskType>
  completionAt?: string | null
  activatedAt?: string
  updatedAt?: string
  details?: TaskDetailsRequestType
  taskAssignees?: Array<{ email?: string; role: number }> | null
  status?: number
  recurrence?: TaskRecurrence
  dueAt?: string | null
  scheduledTask?: Partial<ScheduledTask> | null | undefined
  taskPosition?: 'top' | 'bottom' | 'custom'
  customSortCode?: string
  userTimezone?: string
  manager?: string
  delegationType?: DelegationType
}

type TaskDataAggregate = {
  data: TaskType
  subTasks?: TaskType[]
  routine?: {}
  scheduling?: {}
  customFields?: CustomField | null
  customValues?: CustomFieldValue | null
}

export type Member = {
  id: string
  title: string
  details: string
  category: string
}

export type TaskLists = {
  [key: string]: TaskItem[]
}

type Tasks = {
  list: TaskLists
  periods?: TaskPriorityGroupGlobal[]
  statusList?: TaskStatus[]
  groups?: TaskGroup[]
  currentGroup?: number
}

type TaskDetailsRequestType = {
  description?: string
  flag?: number
  estimatedHour?: string
}

interface UpdateTaskDetailsOptions {
  taskId: string
  details: TaskDetailsRequestType
  sourceDropId?: number | string | undefined
}

export type UploadedFile = {
  id: number
  filename: string
  document: string
}

type Permission = {
  action: string
  field: string
}

const removePaginationQuery = '?page=1&offset=0&limit=20'

interface UpdateTaskOptions {
  taskId: string
  data?: TaskType
  recurrence?: TaskRecurrence | null
  subTasks?: TaskType[]
}

export enum FilterTypeOptions {
  DUE = 'due',
  SCHEDULE = 'schedule',
  COMPLETE = 'complete',
}

type AllTasksFilter = {
  groups?: number[]
  modes?: string[]
  type?: 'due' | 'schedule' | 'complete' | 'delegated' | 'pending'
  priorityGroups?: number[]
  delegatedToMe?: boolean
}

export interface TasksState extends ApiState {
  tasks: Tasks
  delegatedTasks: Tasks
  contacts: Contact[]
  companies: Company[]
  priorityGroups: TaskPriorityGroupGlobal[]
  subscriptionStatus: SubscriptionStatus
  subscriptionEvents: SubscriptionEvents
  isNewLogin: boolean
  getMembers: () => Promise<ListOption>
  createTask: (value: TaskDataAggregate, userId: string) => Promise<TaskItem[]>
  getTasksPriorityGroupGlobal: () => Promise<void>
  getTasksStatus: () => Promise<void>
  getTaskLevels: () => Promise<TaskLevel[]>
  updateTask: (
    options: UpdateTaskOptions,
    userId: string,
  ) => Promise<TaskItem[]>
  updateTaskDetails: (options: UpdateTaskDetailsOptions, userId: string) => void
  getAllTasks: (params: AllTasksFilter) => Promise<Array<Task | TaskItem>>
  getPendingTasks: () => Promise<Array<Task>>
  getIndividualDelegatedTasks: (
    groups?: number[],
  ) => Promise<Array<Task | TaskItem>>
  getDescendants: (id: string, startFromRoot: boolean) => Promise<TaskItem[]>
  deleteTaskById: (
    id: string | string[],
    withChildren?: boolean,
  ) => Promise<void>
  setCurrentGroup: (category: number) => void
  includeTasksAssignedToMe: boolean
  setIncludeTasksAssignedToMe: (newValue: boolean) => void
  taskLevels: TaskLevel[]
  setTaskLevels: () => Promise<void>
  createCompany: (data: Company) => Promise<Company>
  updateCompany: (id: number, data: Partial<Company>) => Promise<Company>
  deleteCompany: (id: number) => Promise<void>
  getCompanies: () => Promise<Company[]>
  setCompanies: (companies: Company[]) => void
  createContact: (data: any) => Promise<Contact>
  updateContact: (id: string, data: Partial<Contact>) => Promise<Contact>
  deleteContact: (id: string) => Promise<void>
  getContacts: () => Promise<Contact[]>
  getTaskById: (id: string) => Promise<Task>
  setContacts: (companies: Contact[]) => void
  createGroup: (group: TaskGroup) => Promise<Group>
  getUserGroups: () => Promise<TaskGroup[]>
  updateUserGroups: (groups: TaskGroup[]) => void
  selectedGroups: number[]
  isAllSelected: () => boolean
  setSelectedGroups: (
    groups: number[],
    ignoreApiCall?: boolean,
  ) => Promise<void>
  inviteGroupMember: (
    group: number,
    email: string,
    role: number,
  ) => Promise<AxiosResponse>
  deleteGroupInvitation: (email: string) => Promise<AxiosResponse>
  resendInvitation: (
    group: number,
    email: string,
    role: number,
  ) => Promise<AxiosResponse>
  resendDelegationInvitation: (email: string, taskId: string) => Promise<void>
  deleteGroup: (id: number) => Promise<void>
  updateGroup: (group: Partial<TaskGroup>) => Promise<Group>
  updateGroupPosition: (groupId: string, nextIndex: number) => Promise<void>
  archiveMemberGroup: (
    groupId: number,
    memberId: number,
    status: number,
  ) => Promise<void>
  updateGroupMember: (groupMemberId: number, roleId: number) => Promise<void>
  deleteGroupMember: (groupMemberId: number) => Promise<void>
  updateTaskState: (
    updatedTasks: TaskItem[],
    insertIfDoesNotExist?: boolean,
  ) => void
  removeFromTaskState: (removedTasks: TaskItem[]) => void
  getTaskByScheduledId: (scheduledId: number) => Promise<TaskItem>
  getTasksPriorityGroupUser: () => Promise<TaskPriorityGroupUser[]>
  createTasksPriorityGroupUser: (
    data: Partial<TaskPriorityGroupUser>,
  ) => Promise<TaskPriorityGroupUser>
  updateTasksPriorityGroupUser: (
    id: number,
    updates: Partial<TaskPriorityGroupUser>,
  ) => Promise<TaskPriorityGroupUser>
  createTasksPriorityGroupUsers: (
    array: Partial<TaskPriorityGroupUser>[],
  ) => Promise<TaskPriorityGroupUser[]>
  updateTasksPriorityGroupUsers: (
    array: { id: number; updates: Partial<TaskPriorityGroupUser> }[],
  ) => Promise<TaskPriorityGroupUser[]>
  shouldUpdateTasks: boolean
  setShouldUpdateTasks: Function
  getTasksLevelsUser: () => Promise<TaskLevelUser[]>
  createTasksLevelsUser: (
    data: Partial<TaskLevelUser>,
  ) => Promise<TaskLevelUser>
  updateTasksLevelsUser: (
    id: number,
    updates: Partial<TaskLevelUser>,
  ) => Promise<TaskLevelUser>
  createTasksLevelsUsers: (
    array: Partial<TaskLevelUser>[],
  ) => Promise<TaskLevelUser[]>
  updateTasksLevelsUsers: (
    array: { id: number; updates: Partial<TaskLevelUser> }[],
  ) => Promise<TaskLevelUser[]>
  routineTimes: UserConfigRoutineTimes
  lastExecutionAssignedTasks: string
  setAssignedTasks: (
    groups?: number[],
    delegatedToMe?: boolean,
  ) => Promise<void>
  moveTaskPositionInMyToday: (
    task: TaskItem,
    newPriorityGroupId: number,
    newCode: string,
  ) => void
  updateStateFlag: (taskId: string, flag: number) => void
  getTaskFiles: (taskId: string) => Promise<UploadedFile[]>
  postTaskFiles: (body: FormData) => Promise<UploadedFile>
  deleteTaskFile: (fileId: number, taskId?: string) => Promise<void>
  updateRoutineTimesState: (routineTimes: UserConfigRoutineTimes) => void
  searchTasks: (query: string) => Promise<Task[]>
  getTaskPermissions: (taskId: string) => Promise<Permission[]>
  getSubscriptionStatus: (
    events: string[],
    target?: string,
    skip?: boolean,
  ) => Promise<CurrentStatus[]>
  getSubscriptionPackage: () => Promise<Subscription>
  setSubscriptionStatus: (status: CurrentStatus) => void
  resetSubscriptionStatus: () => void
  updateSubscriptionStatus: (events: string[], target?: string) => Promise<void>
  createOrUpdateCustomField: (
    data: Partial<CustomField>,
  ) => Promise<CustomField>
  createOrUpdateCustomValue: (
    data: Partial<CustomFieldValue>,
  ) => Promise<CustomFieldValue>
  getCustomFieldById: (id: number) => Promise<CustomField>
  getCustomValueById: (id: number) => Promise<CustomFieldValue>
  getCustomFieldByTaskId: (taskId: string) => Promise<CustomField>
  getCustomValueByTaskId: (
    taskId: string,
    userId?: string,
  ) => Promise<CustomFieldValue[]>
  getTaskReportRoutine: (
    taskId: string,
    type: string,
  ) => Promise<CompletionReportType[]>
  getTaskReportTrend: (
    taskId: string,
    type: string,
  ) => Promise<CompletionReportType[]>
  getTaskReportData: (taskId: string) => Promise<CustomDataReportType[]>
  getUserAvatar: (id: string) => Promise<string>
  dismissTask: (taskId: string) => Promise<void>
  pendingTasks: TaskItem[]
  fetchPendingTasks: () => void
}

const eventStatus: CurrentStatus = {
  currentCount: 0,
  event: '',
  hasAccess: false,
  limit: 0,
  package: '',
  title: '',
}

export const createTasksSlice: StateCreator<
  TasksState,
  [['zustand/immer', never]]
> = (set, get) => ({
  tasks: {
    list: {},
  },
  delegatedTasks: {
    list: {},
  },
  contacts: [],
  companies: [],
  priorityGroups: [],
  subscriptionStatus: {
    licensingEvent: '',
    licensingStatus: '',
    licensingEntity: '',
  },
  subscriptionEvents: {
    ownedGroup: eventStatus,
    ownedGroupTotal: eventStatus,
    groupMembership: eventStatus,
    ownedGroupMemberAmount: eventStatus,
    delegatedTasks: eventStatus,
    activeTasks: eventStatus,
    totalTasks: eventStatus,
    taskAttachments: eventStatus,
    storage: eventStatus,
    integrations: eventStatus,
  },
  includeTasksAssignedToMe: true,
  isNewLogin: true,
  setIncludeTasksAssignedToMe: (newValue) => {
    set({
      includeTasksAssignedToMe: newValue,
    })
  },
  taskLevels: [...new Array(8)].map((item, index) => ({
    id: index + 1,
    title: (index + 1).toString(),
  })),
  setTaskLevels: async () => {
    const response = await get().api!(
      'GET',
      `/task/level${removePaginationQuery}`,
    )
    if (response.status === 200) {
      let levels: TaskLevel[] = response?.data.results
      const responseUser = await get().api!(
        'GET',
        `/task/user/level${removePaginationQuery}`,
      )
      if (responseUser.status === 200) {
        const userLevels: TaskLevelUser[] = responseUser?.data.results
        levels = levels.map((level) => {
          const relatedUserLevel =
            userLevels && userLevels.find((pg) => level.id === pg.parent?.id)
          const title = relatedUserLevel ? relatedUserLevel.title : level.title

          return { ...level, title }
        })
      }
      set({
        taskLevels: levels,
      })
    }
  },
  selectedGroups: [],
  isAllSelected: () => {
    return get().selectedGroups?.length === get().tasks!.groups?.length
  },
  setSelectedGroups: async (groups, ignoreApiCall) => {
    if (!ignoreApiCall && !arraysAreDifferent(get().selectedGroups, groups)) {
      return
    }
    set({
      selectedGroups: groups,
    })
    if (ignoreApiCall) {
      return
    }
    // @ts-ignore
    const selectedLevels = get().selectedLevels
    const preferences: UserPreferences = {
      selectedLevels,
      selectedGroups: groups,
    }
    await get().api!('PATCH', `/user/config/`, { preferences })
  },

  setCurrentGroup: (group) => {
    set((draft) => {
      if (draft.tasks) {
        draft.tasks.currentGroup = group
      }
    })
  },
  moveTaskPositionInMyToday: (task, newPriorityGroupId, newCode) => {
    set((draft) => {
      if (draft.tasks && task.taskSortingCode) {
        const { periodId, taskIndex } = getPriorityGroupIdAndTaskIndex(
          task.id,
          draft.tasks?.list,
        )

        if (periodId !== -1 && taskIndex !== -1) {
          const copySourceList = [...(draft.tasks.list[periodId] || [])]
          copySourceList.splice(taskIndex, 1)

          draft.tasks.list[periodId] = copySourceList
          const taskToAdd = { ...task }
          taskToAdd.taskSortingCode = newCode
          if (!draft.tasks.list[newPriorityGroupId]) {
            draft.tasks.list[newPriorityGroupId] = []
          }
          draft.tasks.list[newPriorityGroupId] = sortTasksByASCIICode([
            ...draft.tasks.list[newPriorityGroupId],
            taskToAdd,
          ])
        }
      }
    })
  },
  updateTaskDetails: ({ taskId, details, sourceDropId }, userId) => {
    get().updateTask({ taskId, data: { details } }, userId)
  },
  createGroup: async ({ title, description, metadata }) => {
    try {
      const response = await get().api!('POST', '/user/group', {
        title,
        metadata,
        description,
      })

      if (response?.status === 200 || response?.status === 201) {
        const groups = get().selectedGroups
        get().setSelectedGroups(groups.concat(response.data.id))
        set((prev) => {
          prev.tasks!.groups = prev.tasks!.groups?.concat(response.data)
        })
      }
      return response?.data
    } catch (error) {
      console.error('An error occurred: ', error)
      throw error
    } finally {
      // Check if the billing status should be updated
      const events = ['ownedGroup', 'ownedGroupTotal', 'groupMembership']
      get().updateSubscriptionStatus(events)
    }
  },
  createTask: async ({ data, subTasks, customFields, customValues }) => {
    let inversePatches: Patch[]
    // @ts-ignore
    if (data.taskAssignees.some((taskAssignee) => taskAssignee.email === get().user.data.email)) {
      const { shouldBeOnTodayList } = taskShouldBePromoted(data)
      if (shouldBeOnTodayList) {
        data.priorityGroup = 0
      }
    }
    data.children = subTasks

    if (data.scheduledTask) {
      delete data.scheduledTask['initialDate']
      data.scheduledTask = parseScheduleToUpdatable(
        data.scheduledTask as ScheduledTask,
      )
    }
    // @ts-ignore
    if (get().shouldAddNextRoutineToCurriculum) {
      // @ts-ignore
      data.templateCurriculum = get().selectedCurriculum
      // If task is to assign curriculum, must be a routine definition
      if (data.priorityGroup !== 6) {
        throw new Error(
          'Please create a routine definition to assign the curriculum',
        )
      }
    }
    try {
      data.userTimezone = getTimeZone().value
      const response = await get().api!('POST', '/task', data)

      const newTasks: TaskItem[] = response.data

      let listUpdatedTasks = [...newTasks]
      for (const updatedTask of newTasks) {
        const { children, ...mainTask } = updatedTask
        listUpdatedTasks = [
          ...listUpdatedTasks,
          ...(children || []).map((child) => ({
            ...child,
            parentTask: mainTask,
          })),
        ]
      }

      get().updateTaskState(listUpdatedTasks, true)
      set((draft) => {
        draft.shouldUpdateTasks = true
      })

      if (newTasks.length > 0 && customFields?.customField) {
        const newCustomField = await get().createOrUpdateCustomField({
          ...customFields,
          task: newTasks[0].id,
        })

        // If the user has provided customValues but the values are not related to a template
        if (
          !customValues?.customField &&
          Object.values(customValues?.customValue || {}).filter(
            (value) => !!value,
          ).length
        ) {
          await get().createOrUpdateCustomValue({
            customField: newCustomField.id,
            customValue: customValues?.customValue,
          })
        }
      }

      return newTasks
    } catch (error) {
      set((draft) => {
        if (inversePatches) {
          return applyPatches(draft, inversePatches)
        }
      })
      console.error('An error occurred while creating the task: ', error)
      throw error
    } finally {
      const events: string[] = [
        'activeTasks',
        'delegatedTasks',
        'totalRoutineInstances',
        'totalTasks',
      ]
      get().updateSubscriptionStatus(events)
    }
  },
  updateStateFlag: (taskId, flag) => {
    set((draft) => {
      if (!draft.tasks?.list) {
        return
      }
      const { periodId, taskIndex } = getPriorityGroupIdAndTaskIndex(
        taskId,
        draft.tasks?.list,
      )
      if (periodId === -1 || taskIndex === -1) {
        return
      }
      const task = draft.tasks.list[periodId][taskIndex]
      if (!task.taskDetails) {
        task.taskDetails = {} as TaskDetails
      }
      task.taskDetails!.flag = { id: flag, title: '' }
      draft.tasks.list[periodId][taskIndex] = task
    })
  },
  updateTask: async ({ taskId, data: updates, subTasks }) => {
    if (!taskId) {
      throw new Error('Task ID is undefined')
    }
    if (updates?.status === TaskStatusId.DONE) {
      // @ts-ignore
      get().setShowConfetti(true)
    }

    if (updates && subTasks?.length) {
      updates.children = subTasks
    }

    if (updates?.status === TaskStatusId.DONE) {
      updates.completionAt = new Date().toISOString()
      updates.mode = TaskMode.COMPLETED
    }

    if (updates?.scheduledTask) {
      if (updates?.scheduledTask?.recurRule) {
        if (!updates?.scheduledTask?.initialDate) {
          updates.priorityGroup = TaskPriorityGroup.RoutineDefinition
        }
      } else {
        const { shouldBeOnTodayList } = taskShouldBePromoted(updates)
        updates.priorityGroup = shouldBeOnTodayList
          ? TaskPriorityGroup.Now
          : TaskPriorityGroup.Scheduled
      }
      updates.scheduledTask = parseScheduleToUpdatable(
        updates.scheduledTask as ScheduledTask,
      )
    } else if (updates?.scheduledTask === null) {
      if (updates.priorityGroup === undefined) {
        updates.priorityGroup = TaskPriorityGroup.Now
      }
    }

    if (updates?.taskPosition !== 'custom') {
      const { shouldBePromoted, shouldBeOnTodayList, shouldBePlacedAtBottom } =
        taskShouldBePromoted(updates)

      if (shouldBePlacedAtBottom) {
        updates!.taskPosition = 'bottom'
      } else if (shouldBePromoted) {
        updates!.taskPosition = 'top'
      }
      if (shouldBeOnTodayList) {
        updates!.priorityGroup = 0
      }
    }

    try {
      updates!.userTimezone = getTimeZone().value
      const response = await get().api!('PATCH', `/task/${taskId}`, updates)
      const updatedTasks: TaskItem[] = response.data

      let listUpdatedTasks = [...updatedTasks.filter((task) => !!task.id)]
      for (const updatedTask of updatedTasks.filter((task) => !!task.id)) {
        const { children, ...mainTask } = updatedTask
        listUpdatedTasks = [
          ...listUpdatedTasks,
          ...(children || []).map((child) => ({
            ...child,
            parentTask: mainTask,
          })),
        ]
      }

      get().updateTaskState(listUpdatedTasks, true)
      set((draft) => {
        draft.shouldUpdateTasks = true
      })
      return updatedTasks.filter((task) => !!task.id)
    } catch (error) {
      console.error('An error occurred while updating the task: ', error)
      throw error
    } finally {
      // Check if the billing status should be updated
      const events: string[] = [
        'activeTasks',
        'delegatedTasks',
        'totalRoutineInstances',
        'totalTasks',
      ]
      get().updateSubscriptionStatus(events)
    }
  },
  getMembers: async () => {
    const response = await get().api!(
      'GET',
      `/users/all/${removePaginationQuery}`,
    )

    return response?.data
  },
  getTasksPriorityGroupGlobal: async () => {
    const response = await get().api!(
      'GET',
      `/task/priority-group${removePaginationQuery}`,
    )
    if (response.status === 200) {
      let priorityGroups: TaskPriorityGroupGlobal[] = response?.data.results

      const responseUser = await get().api!(
        'GET',
        `/task/user/priority-group${removePaginationQuery}`,
      )
      if (responseUser.status === 200) {
        const userPriorityGroups: TaskPriorityGroupUser[] =
          responseUser?.data.results

        priorityGroups = priorityGroups.map((priorityGroup) => {
          const relatedPriorityGroup =
            userPriorityGroups &&
            userPriorityGroups.find((pg) => priorityGroup.id === pg.parent?.id)
          const title = relatedPriorityGroup
            ? relatedPriorityGroup.title
            : priorityGroup.title

          return { ...priorityGroup, title }
        })
      }

      set((draft) => {
        draft.priorityGroups = priorityGroups
      })
    }
  },
  getTasksStatus: async () => {
    const response = await get().api!(
      'GET',
      `/task/status${removePaginationQuery}`,
    )

    if (response.status === 200) {
      const statusList = response?.data.results

      set((draft) => {
        return produce(draft, (draft: Draft<TasksState>) => {
          if (draft.tasks) {
            draft.tasks.statusList = statusList
          }
        })
      })
    }
  },
  getTaskLevels: async () => {
    const response = await get().api!(
      'GET',
      `/task/level${removePaginationQuery}`,
    )
    const result: TaskLevel[] = response?.data.results
    return result
  },
  getAllTasks: async ({
    groups,
    modes,
    type,
    priorityGroups,
    delegatedToMe,
  }) => {
    const filters = new Map<string, string | string[] | number[]>()
    if (groups) {
      if (groups.length === 0) {
        // Send a value in group parameter when used deselect all groups
        filters.set('group', [0])
      } else {
        filters.set('group', groups)
      }
    }
    if (modes) filters.set('mode', modes)
    if (delegatedToMe) {
      filters.set('type', 'delegated_to_me')
    } else if (type) {
      filters.set('type', type)
    }
    if (priorityGroups) {
      filters.set('priority_group', priorityGroups)
    }
    const filterQuery = buildFilterQuery(filters)
    const response: AxiosResponse = await get().api!(
      'GET',
      `/task?${filterQuery}`,
    )
    const results: Task[] = response.data
    return results
  },
  getPendingTasks: async () => {
    const response = await get().api!('GET', '/task/pending')
    return response.data
  },
  getIndividualDelegatedTasks: async (groups) => {
    if ((groups || []).length === 0) {
      groups = [0]
    }
    const response = await get().api!(
      'GET',
      `/task/individual-delegated-parent?group=${(groups || []).join()}`,
    )
    return response.data
  },
  getDescendants: async (id, startFromRoot) => {
    const response: AxiosResponse = await get().api!(
      'GET',
      `/task/descendants/list?task_id=${id}&start_from_root=${startFromRoot}`,
    )
    return response.data
  },
  deleteTaskById: async (id, withChildren = false) => {
    if (!Array.isArray(id)) {
      id = [id]
    }
    try {
      await get().api!('DELETE', `/task`, { tasks: id, withChildren })

      set((draft) => {
        draft.shouldUpdateTasks = true
      })
    } catch (error) {
      throw error
    } finally {
      // Check if the billing status should be updated
      const events: string[] = [
        'activeTasks',
        'delegatedTasks',
        'totalRoutineInstances',
        'totalTasks',
      ]
      get().updateSubscriptionStatus(events)
    }
  },
  createCompany: async (data: any) => {
    const response = await get().api!('POST', '/contact/company', data)
    return response?.data
  },
  updateCompany: async (id, data) => {
    const response = await get().api!('PATCH', `/contact/company/${id}`, data)
    return response?.data
  },
  deleteCompany: async (id) => {
    const response = await get().api!('DELETE', `/contact/company/${id}`)
    return response?.data
  },
  getCompanies: async () => {
    const response = await get().api!(
      'GET',
      `/contact/company${removePaginationQuery}`,
    )
    const result: Company[] = response?.data.results
    set((draft) => {
      draft.companies = result
    })
    return result
  },
  setCompanies: (companies) => {
    set((draft) => {
      draft.companies = companies
    })
  },
  createContact: async (data: any) => {
    const response = await get().api!('POST', '/contact/', data)

    return response?.data
  },
  updateContact: async (id, data) => {
    if (!data.contactCompany && data.contactCompanyDetails?.length === 1) {
      data.contactCompany = { ...data.contactCompanyDetails[0] }
    }
    const response = await get().api!('PATCH', `/contact/${id}`, data)
    return response?.data
  },
  deleteContact: async (id) => {
    const response = await get().api!('DELETE', `/contact/${id}`)
    return response?.data
  },
  getContacts: async () => {
    const response = await get().api!(
      'GET',
      `/contact/${removePaginationQuery}`,
    )
    const result: Contact[] = response?.data.results
    set((draft) => {
      draft.contacts = result
    })

    return result
  },
  setContacts: (contacts) => {
    set((draft) => {
      draft.contacts = contacts
    })
  },
  getTaskById: async (id: string) => {
    const response = await get().api!('GET', `/task/${id}`)
    const task: TaskItem = response.data
    get().updateTaskState([task], true)
    return response.data
  },
  getUserGroups: async () => {
    const response: AxiosResponse = await get().api!('GET', `/group/member`)
    const result: TaskGroup[] = response?.data
    const sortedOrder = result.sort((a, b) => a.userOrder - b.userOrder)

    if (response.status === 200) {
      set((prev) => {
        prev.tasks!.groups = getGroupsWithAlternativeNames(sortedOrder)
      })
    }
    return result
  },
  updateUserGroups: (groups: TaskGroup[]) => {
    set((draft) => {
      draft.tasks.groups = groups
    })
  },
  updateGroupPosition: async (groupId: string, nextIndex: number) => {
    const response = await get().api!('PUT', '/group/order', {
      groupId: Number(groupId),
      nextIndex,
    }).catch((e) => {
      throw e.response?.data?.error
    })

    return response?.data
  },
  inviteGroupMember: async (group, email, role) => {
    try {
      const response = await get().api!('POST', '/group/member/invitation', {
        group,
        email,
        role,
      })
      await get().getUserGroups()
      return response?.data
    } catch (error) {
      console.error('An error occurred: ', error)
      throw error
    } finally {
      // Check if the billing status should be updated
      const events: string[] = ['ownedGroupMemberAmount']
      get().updateSubscriptionStatus(events, `${group}`)
    }
  },
  deleteGroupInvitation: async (email: string) => {
    const response = await get().api!(
      'DELETE',
      `/group/member/invitation?email=${encodeURIComponent(email)}`,
    )
    if (response.status === 200) {
      await get().getUserGroups()
    }
    return response
  },
  resendInvitation: async (group, email, role) => {
    const response = await get().deleteGroupInvitation(email)
    if (response?.status === 200) {
      return get().inviteGroupMember(group, email, role)
    }
    return response
  },
  resendDelegationInvitation: async (email, taskId) => {
    const data = { email, task: taskId }
    const response = await get().api!(
      'PUT',
      `/task/delegation/invitation`,
      data,
    )
    return response?.data
  },
  deleteGroup: async (id: number) => {
    try {
      const response = await get().api!('DELETE', `/user/group/${id}`)

      if (response?.status >= 200 && response?.status < 300) {
        await get().getUserGroups()
        const groups = get().selectedGroups
        const index = groups.findIndex((selectedGroup) => selectedGroup === id)
        if (index !== -1) {
          groups.splice(index, 1)
          get().setSelectedGroups(groups)
        }
      }
      return response?.data
    } catch (error) {
      console.error('An error occurred: ', error)
      throw error
    } finally {
      // Check if the billing status should be updated
      const events = ['ownedGroup', 'ownedGroupTotal', 'groupMembership']
      get().updateSubscriptionStatus(events)
    }
  },
  updateGroup: async (group) => {
    const response = await get().api!('PATCH', `/user/group/${group.id}`, group)

    if (response?.status === 200) {
      get().getUserGroups()
      if (group.status === 2) {
        const groups = get().selectedGroups
        const index = groups.findIndex(
          (selectedGroup) => selectedGroup === group.id,
        )
        if (index !== -1) {
          groups.splice(index, 1)
          get().setSelectedGroups(groups)
        }
      }
    }
    return response?.data
  },
  archiveMemberGroup: async (
    groupId: number,
    memberId: number,
    status: number,
  ) => {
    const response = await get().api!('PATCH', `/group/member/${memberId}`, {
      status,
    })

    if (response?.status === 200) {
      await get().getUserGroups()
      if (status === 2) {
        const groups = get().selectedGroups
        const index = groups.findIndex(
          (selectedGroup) => selectedGroup === groupId,
        )
        if (index !== -1) {
          groups.splice(index, 1)
          get().setSelectedGroups(groups)
        }
      }
    }
    return response?.data
  },
  updateGroupMember: async (groupMemberId, roleId) => {
    const data = { role: roleId }
    const response = await get().api!(
      'PATCH',
      `/group/member/${groupMemberId}`,
      data,
    )
    if (response?.status >= 200 && response?.status < 300) {
      await get().getUserGroups()
    }
    return response?.data
  },
  deleteGroupMember: async (groupMemberId: number) => {
    const response = await get().api!(
      'DELETE',
      `/group/member/${groupMemberId}`,
    )

    if (response?.status >= 200 && response?.status < 300) {
      await get().getUserGroups()
    }
    return response?.data
  },
  getTaskByScheduledId: async (scheduledId: number) => {
    const response = await get().api!('GET', `/task/schedule/${scheduledId}`)

    if (response?.status === 404) {
      return undefined
    }

    return response?.data
  },
  removeFromTaskState: (deletedTasks: TaskItem[]) => {
    set((draft) => {
      for (const newDraft of deletedTasks) {
        const { periodId, taskIndex } = getPriorityGroupIdAndTaskIndex(
          newDraft.id,
          draft.tasks?.list,
        )

        if (!(periodId === -1 || taskIndex === -1)) {
          draft.tasks.list[periodId].splice(taskIndex, 1)
        }
      }
    })
  },
  updateTaskState: (updatedTasks, insertIfDoesNotExist) => {
    set((draft) => {
      for (const newDraft of updatedTasks) {
        const { periodId, taskIndex } = getPriorityGroupIdAndTaskIndex(
          newDraft.id,
          draft.tasks?.list,
        )
        let existsInList = !(periodId === -1 || taskIndex === -1)

        // If task doesn't have a sorting code or if by flexible time should not be shown
        if (
          !newDraft.taskSortingCode ||
          filterTasksByFlexibleTime([newDraft], get().routineTimes).length === 0
        ) {
          if (existsInList) {
            draft.tasks.list[periodId].splice(taskIndex, 1)
          }
          continue
        }

        if (periodId !== newDraft.priorityGroup?.id) {
          if (existsInList) {
            draft.tasks.list[periodId].splice(taskIndex, 1)
            existsInList = false
          }
        }

        const priorityGroupId = newDraft.priorityGroup?.id || 0
        if (!draft.tasks.list[priorityGroupId]) {
          draft.tasks.list[priorityGroupId] = []
        }

        if (existsInList) {
          draft.tasks.list[priorityGroupId][taskIndex] = newDraft
        } else if (insertIfDoesNotExist) {
          draft.tasks.list[priorityGroupId].unshift(newDraft)
        }

        draft.tasks.list[priorityGroupId] = sortTasksByASCIICode(
          draft.tasks.list[priorityGroupId],
        )
      }
    })
  },
  getTasksPriorityGroupUser: async () => {
    const response = await get().api!(
      'GET',
      `/task/user/priority-group${removePaginationQuery}`,
    )
    const result: TaskPriorityGroupUser[] = response?.data.results
    return result
  },
  createTasksPriorityGroupUser: async (
    data: Partial<TaskPriorityGroupUser>,
  ) => {
    const response = await get().api!('POST', '/task/user/priority-group', data)
    return response?.data
  },
  updateTasksPriorityGroupUser: async (
    id: number,
    updates: Partial<TaskPriorityGroupUser>,
  ) => {
    const response = await get().api!(
      'PATCH',
      `/task/user/priority-group/${id}`,
      updates,
    )
    return response?.data
  },
  createTasksPriorityGroupUsers: async (
    array: Partial<TaskPriorityGroupUser>[],
  ) => {
    const modifiedTasks: TaskPriorityGroupUser[] = []
    for (let index = 0; index < array.length; index++) {
      const updated = await get().createTasksPriorityGroupUser(array[index])
      modifiedTasks.push(updated)
    }
    return modifiedTasks
  },
  updateTasksPriorityGroupUsers: async (
    array: { id: number; updates: Partial<TaskPriorityGroupUser> }[],
  ) => {
    const modifiedTasks: TaskPriorityGroupUser[] = []
    for (let index = 0; index < array.length; index++) {
      const update = array[index]
      const updated = await get().updateTasksPriorityGroupUser(
        update.id,
        update.updates,
      )
      modifiedTasks.push(updated)
    }
    return modifiedTasks
  },
  shouldUpdateTasks: true,
  setShouldUpdateTasks: (newShouldUpdateTasks: boolean) => {
    set((draft) => {
      draft.shouldUpdateTasks = newShouldUpdateTasks
    })
  },
  getTasksLevelsUser: async () => {
    const response = await get().api!(
      'GET',
      `/task/user/level${removePaginationQuery}`,
    )
    const result: TaskLevelUser[] = response?.data.results
    return result
  },
  createTasksLevelsUser: async (data: Partial<TaskLevelUser>) => {
    const response = await get().api!('POST', '/task/user/level', data)
    return response?.data
  },
  updateTasksLevelsUser: async (
    id: number,
    updates: Partial<TaskLevelUser>,
  ) => {
    const response = await get().api!(
      'PATCH',
      `/task/user/level/${id}`,
      updates,
    )
    return response?.data
  },
  createTasksLevelsUsers: async (array: Partial<TaskLevelUser>[]) => {
    const modifiedTasks: TaskLevelUser[] = []
    for (let index = 0; index < array.length; index++) {
      const updated = await get().createTasksLevelsUser(array[index])
      modifiedTasks.push(updated)
    }
    return modifiedTasks
  },
  updateTasksLevelsUsers: async (
    array: { id: number; updates: Partial<TaskLevelUser> }[],
  ) => {
    const modifiedTasks: TaskLevelUser[] = []
    for (let index = 0; index < array.length; index++) {
      const update = array[index]
      const updated = await get().updateTasksLevelsUser(
        update.id,
        update.updates,
      )
      modifiedTasks.push(updated)
    }
    return modifiedTasks
  },
  routineTimes: { beginning: '03:00', middle: '11:00', end: '19:00' },
  lastExecutionAssignedTasks: dayjs().toISOString(),
  setAssignedTasks: async (groups?, delegatedToMe?) => {
    const filters = new Map<string, string | string[] | number[]>()
    if (groups) {
      if (groups.length === 0) {
        // Send a value in group parameter when used deselect all groups
        filters.set('group', [0])
      } else {
        filters.set('group', groups)
      }
      if (delegatedToMe !== undefined) {
        filters.set('is_owner', delegatedToMe ? '0' : '1')
      }
    }
    const filterQuery = buildFilterQuery(filters)

    const response: AxiosResponse = await get().api!(
      'GET',
      `/task/assigned?${filterQuery}`,
    )
    const results: TaskItem[] = response?.data

    if (results) {
      set((draft: Draft<TasksState>) => {
        if (draft.tasks) {
          const filteredTasks = filterTasksByFlexibleTime(
            results,
            get().routineTimes,
          )
          const taskList = divideTasksByPeriod(filteredTasks)
          draft.tasks.list = taskList
          draft.lastExecutionAssignedTasks = dayjs().toISOString()
        }
      })
    }
  },
  getTaskFiles: async (taskId) => {
    const response = await get().api!('GET', `task/attachment/${taskId}`)
    return response.data
  },
  postTaskFiles: async (body) => {
    const response = await get().api!('POST', 'task/attachment', body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const taskId = response?.data?.task
    if (taskId) {
      // Check if the billing status should be updated
      const events: string[] = ['taskAttachments', 'storage']
      get().updateSubscriptionStatus(events, taskId)
    }

    return response.data
  },
  deleteTaskFile: async (fileId, taskId) => {
    const response = await get().api!('DELETE', `task/attachment/${fileId}`)
    if (taskId) {
      // Check if the billing status should be updated
      const events: string[] = ['taskAttachments', 'storage']
      get().updateSubscriptionStatus(events, taskId)
    }
    return response.data
  },
  updateRoutineTimesState: (routineTimes: UserConfigRoutineTimes) => {
    if (routineTimes) {
      set((draft) => {
        draft.routineTimes = routineTimes
      })
    }
  },
  searchTasks: async (query: string) => {
    const filters = new Map<string, string | string[] | number[]>()
    const groups = get().selectedGroups
    if (groups) filters.set('groups', groups)
    filters.set('search', query)
    const filterQuery = buildFilterQuery(filters)
    const response = await get().api!('GET', `task/search?${filterQuery}`)
    return response.data
  },
  getTaskPermissions: async (taskId) => {
    const response = await get().api!('GET', `/task/permission/${taskId}`)
    return response.data
  },
  getSubscriptionStatus: async (events, target, skip) => {
    let params = `event=${events.join(',')}`
    if (target) {
      params += `&target=${target}`
    }
    const response = await get().api!(
      'GET',
      `/user/subscription/limit?${decamelize(params)}`,
    )
    if (response.status === 200) {
      if (response?.data?.details) {
        const { details } = response.data
        for (const detail of details) {
          set((draft) => {
            const currentCount = detail?.currentStorage || detail?.currentCount
            const met = currentCount === detail.limit
            const exceeded = currentCount > detail.limit
            const event = camelize(detail.event)
            const status = exceeded ? 'exceeded' : met ? 'met' : 'ok'

            draft.subscriptionEvents[event as keyof SubscriptionEvents] = {
              ...detail,
              currentCount: currentCount,
              status: status,
            }
          })
        }
      }
      return response.data.details
    }
    throw new Error()
  },
  getSubscriptionPackage: async () => {
    const response = await get().api!('GET', `/user/subscription`)

    return response.data.subscription
  },
  setSubscriptionStatus: (status) => {
    if (status) {
      set((draft) => {
        if (draft && draft.subscriptionStatus) {
          const met = status.currentCount === status.limit

          const exceeded = status.currentCount > status.limit
          const event = camelize(status.event)
          // Don't update or disable if there is an exceeded event
          if (
            draft.subscriptionStatus.licensingEvent !== event &&
            draft.subscriptionStatus.licensingStatus === 'exceeded'
          ) {
            return
          }
          if (exceeded) {
            draft.subscriptionStatus.licensingStatus = 'exceeded'
          }
          if (met) {
            draft.subscriptionStatus.licensingStatus = 'met'
          }
          draft.subscriptionStatus.licensingEvent = event
          if (!exceeded && !met) {
            draft.subscriptionStatus.licensingStatus = ''
            draft.subscriptionStatus.licensingEvent = ''
          }
        }
      })
    }
  },
  resetSubscriptionStatus: () => {
    set((draft) => {
      if (draft && draft?.subscriptionStatus) {
        draft.subscriptionStatus.licensingStatus = ''
        draft.subscriptionStatus.licensingEvent = ''
        draft.subscriptionStatus.licensingEntity = ''
      }
    })
  },
  updateSubscriptionStatus: async (events, target) => {
    try {
      let params = `event=${decamelize(events.join(','))}`
      if (target) {
        params += `&target=${target}`
      }
      // Check if we should turn off the met or exceeded flag
      const response = await get().api!(
        'GET',
        `/user/subscription/limit?${params}`,
      )
      if (events.indexOf('ownedGroupMemberAmount') !== -1 && target) {
        const groups = get().tasks?.groups || []
        const entityName = groups.find(
          (group) => `${group.id}` === target,
        )?.title
        if (entityName) {
          set((draft) => {
            if (draft && draft?.subscriptionStatus) {
              draft.subscriptionStatus.licensingEntity = entityName
            }
          })
        }
      }
      if (response?.data?.details) {
        const { details } = response.data
        const prevStatus = get().subscriptionStatus
        const updateCurrentStatus =
          !prevStatus.licensingEvent ||
          events.indexOf(prevStatus.licensingEvent) !== -1
        const newStatus: SubscriptionStatus = updateCurrentStatus
          ? {
              licensingEvent: '',
              licensingStatus: '',
              licensingEntity: '',
            }
          : prevStatus

        for (const detail of details) {
          set((draft) => {
            type Status = 'exceeded' | 'met' | 'ok'
            const values: Record<Status, number> = {
              exceeded: 2,
              met: 1,
              ok: 0,
            }
            const currentCount = detail?.currentStorage || detail?.currentCount
            const met = currentCount === detail.limit
            const exceeded = currentCount > detail.limit
            const event = camelize(detail.event)
            const status: Status = exceeded ? 'exceeded' : met ? 'met' : 'ok'
            draft.subscriptionEvents[event as keyof SubscriptionEvents] = {
              ...detail,
              currentCount: currentCount,
              status: status,
            }

            // Update subscription status
            const currentValue =
              values[(newStatus.licensingStatus as Status) || 'ok']
            const newValue = values[status]
            if (!currentValue && !newValue) {
              draft.subscriptionStatus.licensingEvent = ''
              draft.subscriptionStatus.licensingStatus = ''
              newStatus.licensingEvent = ''
              newStatus.licensingStatus = ''
            } else if (newValue > currentValue) {
              draft.subscriptionStatus.licensingEvent = event
              draft.subscriptionStatus.licensingStatus = status
              newStatus.licensingEvent = event
              newStatus.licensingStatus = status
            }
          })
        }
      }
    } catch (error) {
      console.error('An error occurred while creating the task: ', error)
      throw error
    }
  },
  createOrUpdateCustomField: async (data: Partial<CustomField>) => {
    const url = '/task/custom/field'
    if (data.id) {
      const response = await get().api!('PATCH', `${url}/${data.id}`, data)
      return response?.data
    }
    const response = await get().api!('POST', url, data)
    return response?.data
  },
  createOrUpdateCustomValue: async (data: Partial<CustomFieldValue>) => {
    const url = '/task/custom/value'
    if (data.id) {
      const response = await get().api!('PATCH', `${url}/${data.id}`, data)
      return response?.data
    }
    const response = await get().api!('POST', url, data)
    return response?.data
  },
  getCustomFieldById: async (customFieldId: number) => {
    const response = await get().api!(
      'GET',
      `/task/custom/field/${customFieldId}`,
    )
    return response?.data
  },
  getCustomValueById: async (customFieldId: number) => {
    const response = await get().api!(
      'GET',
      `/task/custom/value/${customFieldId}`,
    )
    return response?.data
  },
  getCustomFieldByTaskId: async (taskId) => {
    const response = await get().api!(
      'GET',
      `/task/custom/field/search/${taskId}`,
    )
    if (Array.isArray(response?.data)) {
      if (response?.data.length === 0) {
        return undefined
      }
    }
    return response?.data
  },
  getCustomValueByTaskId: async (taskId, userId) => {
    const filters = new Map<string, string | string[] | number[]>()
    filters.set('task', taskId)
    if (userId) {
      filters.set('user', userId)
    }
    const filterQuery = buildFilterQuery(filters)
    const response = await get().api!(
      'GET',
      `/task/custom/value/search?${filterQuery}`,
    )
    return response?.data
  },
  getTaskReportRoutine: async (taskId, type) => {
    const filters = new Map<string, string | string[] | number[]>()
    filters.set('type', type)
    const filterQuery = buildFilterQuery(filters)
    const response = await get().api!(
      'GET',
      `/task/report/${taskId}/routine?${filterQuery}`,
    )
    return response.data
  },
  getTaskReportTrend: async (taskId, type) => {
    const filters = new Map<string, string | string[] | number[]>()
    filters.set('type', type)
    const filterQuery = buildFilterQuery(filters)
    const response = await get().api!(
      'GET',
      `/task/report/${taskId}/trend?${filterQuery}`,
    )
    return response.data
  },
  getTaskReportData: async (taskId) => {
    const response = await get().api!('GET', `/task/report/${taskId}/data`)
    return response.data
  },
  getUserAvatar: async (id) => {
    const response = await get().api!(
      'GET',
      `/user/avatar/${id}?size=thumbnail`,
    )
    return response.data
  },
  dismissTask: async (taskId: string) => {
    await get().api!('PATCH', `/task/dismiss/${taskId}`)
  },
  fetchPendingTasks: () => {
    get().getPendingTasks().then((tasks) => {
      set((draft) => {
        draft.pendingTasks = tasks as TaskItem[]
      })
    })
  },
  pendingTasks: [],
})
