import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import { AvatarSize } from 'antd/es/avatar/AvatarContext'
import clsx from 'clsx'
import { AssigneeInvitationStatus } from 'utils/taskUtils'
import styles from './UserAvatar.module.scss'

export enum Size {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
}

export type UserAvatarProps = {
  label?: string
  size?: AvatarSize
  image?: string
  color?: string
  delegationStatus?: string
  avatarStyles?: {}
}

export const getInitials = (label: string): string | null => {
  const initials = label
    .split(' ')
    .map((part) => part.charAt(0))
    .slice(0, 3)
    .join('')
    .toUpperCase()
  return initials
}

const UserAvatar = ({
  label = '',
  size = 'small',
  image = '',
  color = '',
  delegationStatus = '',
  avatarStyles,
  ...props
}: UserAvatarProps) => {
  const iconClassName =
    delegationStatus &&
    styles[delegationStatus.replaceAll(' ', '').toLowerCase() + 'Status']

  const avatarStyle = color
    ? {
        backgroundColor: color,
        color: 'var(--background-color)',
        ...avatarStyles,
      }
    : {
        backgroundColor: `var(${
          image ? '--user-avatar-image-background' : '--user-avatar-background'
        })`,
        color: 'white',
        ...avatarStyles,
      }

  if (image) {
    return (
      <Avatar
        size={size}
        style={avatarStyle}
        src={image}
        className={clsx(styles.avatarImage, iconClassName)}
        {...props}
      />
    )
  }

  if (delegationStatus === AssigneeInvitationStatus.PENDING) {
    return (
      <Avatar
        size={size}
        style={{
          backgroundColor: 'white',
          color: 'red',
          border: '1px solid red',
          ...avatarStyles,
        }}
        className={clsx(styles.avatarImage)}
        icon={<UserOutlined />}
        {...props}
      />
    )
  }

  return (
    <Avatar
      size={size}
      style={avatarStyle}
      className={iconClassName}
      {...props}
    >
      {getInitials(label)}
    </Avatar>
  )
}

export default UserAvatar
