// src/services/Survey.slice.ts
import { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import axios from 'axios'
import apiBase from 'utils/axiosBase'

// Define types for survey questions and answers
export interface SurveyQuestion {
  id: number;
  question: string;
  type: 'text' | 'options';
  options?: string[];
}

export interface BusinessPlanSurvey {
  prompt: string;
  questions: SurveyQuestion[];
}

export interface SurveyAnswers {
  [key: string]: string;
}

// Define the state interface for the survey slice
export interface SurveyState extends ApiState {
  fetchBusinessPlanSurvey: () => Promise<BusinessPlanSurvey>;
  saveSurveyData: (payload: any) => Promise<any>;
  requestConfirmSurveyAccount: (key: string) => Promise<any>;
  confirmSurveyAccount: (payload: {key: string, password: string}) => Promise<any>;
  getSurveyStatus: () => Promise<any>;
  getSurveyAffiliate: (affiliateId: string) => Promise<any>;
}

// Create the slice
export const createSurveySlice: StateCreator<
  SurveyState,
  [['zustand/immer', never]]
> = (set, get) => ({
  // API methods
  fetchBusinessPlanSurvey: async () => {
    try {
      // Use axios directly instead of the authenticated api method
      const response = await axios.get('/api/v1/business-plan-survey');
      
      // Ensure questions is an array even if it comes back as an empty object
      const questions = Array.isArray(response.data.questions) 
        ? response.data.questions 
        : [];
      
      const surveyData = {
        prompt: response.data.prompt,
        questions: questions
      };
      
      return surveyData;
    } catch (error: any) {
      console.error('Failed to fetch survey:', error);
      throw error;
    }
  },
  saveSurveyData: async (payload: any) => {
    try { 
      const response = await apiBase.post('/survey/confirmation', payload);      
      return response.data;
    } catch (error: any) {
      console.error('Failed to save survey answers:', error); 
      throw error;
    }
  },
  requestConfirmSurveyAccount: async (key: string) => {
    try {
      const response = await apiBase.get(`/survey/confirmation/request?key=${key}`);
      return response.data;
    } catch (error: any) {  
      console.error('Failed to confirm survey account:', error);
      throw error;
    }
  },
  confirmSurveyAccount: async (payload: {key: string, password: string}) => {
    try {
      const response = await apiBase.post(`/survey/confirmation/request`, payload);
      return response.data;
    } catch (error: any) {  
      console.error('Failed to confirm survey account:', error);
      throw error;
    }
  },
  getSurveyStatus: async () => {
    try {

      const accessToken = localStorage.getItem('tepin-access-token'); 
      
      if (!accessToken) {
        throw new Error('No access token found');
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }
    
      const response = await apiBase.get('/api/v1/user/config/', { headers })
      return response.data;
     
    } catch (error: any) {
      console.error('Failed to get survey status:', error);
      throw error;
    }
  },
  getSurveyAffiliate: async (affiliateId: string) => {
    try {
      const response = await axios.get(`/survey/affiliation?affiliateId=${affiliateId}`);
      return response.data;
    } catch (error: any) {
      console.error('Failed to get survey affiliate:', error);
      throw error;
    }
  }
});
