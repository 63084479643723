import { TaskType } from 'services/Tasks.slice'
import {
  TaskRolePermissionResponse,
  TaskRolePermissions,
} from 'types/Permission'
import { TaskGroup } from 'types/Tasks'

export const TaskFields = Object.freeze({
  ACTIVATED_AT: 'usertask.activatedAt',
  COMPLETION_AT: 'usertask.completionAt',
  DESCRIPTION: 'usertaskdetails.description',
  DUE_AT: 'usertask.dueAt',
  FLAG: 'usertaskdetails.flag',
  GROUP: 'usertask.group',
  LEVEL: 'usertask.level',
  MANAGE_ROLE: 'usertaskrole.manageRole',
  MODE: 'usertask.mode',
  PRIORITY_GROUP: 'usertask.priorityGroup',
  SCHEDULED_TASK: 'taskscheduling.scheduledTask',
  STATUS: 'usertask.status',
  TASK_ASSIGNEES: 'usertaskassignee.taskAssignees',
  TASK_DATA: 'usertask.taskData',
  TITLE: 'usertask.title',
  USER: 'usertask.user',
  TASK_MANAGER: 'usertask.manager',
})

export const TaskActions = Object.freeze({
  ADD_ATTACHMENTS: 'taskattachments.addAttachments',
  ADD_CHILDREN: 'usertask.addChildren',
  ADD_PARENT: 'usertask.addParent',
  // TODO: Update with the real permission
  CUSTOM_FIELDS: 'usertask.title',
  // TODO: Update with the real permission
  CUSTOM_VALUES: 'usertask.priorityGroup',
  DELETE_TASK: 'usertask.deleteTask',
  ENABLE_CHAT: 'usertask.enableChat',
  READ_TASK: 'usertask.readTask',
  DELEGATE_TYPE: 'usertask.delegationType',
})

export const TaskInternals = Object.freeze({
  SUBMIT_TASK: 'usertask.canSubmitTaskForm',
  SUBMIT_GROUP: 'usergroup.canSubmitGroupForm',
})

export const GroupFields = Object.freeze({
  TITLE: 'usergroup.title',
  METADATA: 'usergroup.metadata',
  STATUS: 'usergroup.status',
})

export const GroupAssociated = Object.freeze({
  MANAGE_USER: 'usergroupassociated.manageUser',
  UPDATE_STATUS: 'usergroupassociated.status',
  RESEND_INVITE: 'usergroupassociated.resendInvite',
  MANAGE_ADMINS: 'usergroupassociated.manageAdmins',
})

export const GroupActions = Object.freeze({
  DELETE_GROUP: 'usergroup.deleteGroup',
})

export const TaskModels = Object.freeze({
  TASK_SCHEDULING: 'taskscheduling',
  USER_TASK: 'usertask',
  USER_TASK_ASSIGNEE: 'usertaskassignee',
  USER_TASK_DETAILS: 'usertaskdetails',
  USER_TASK_ROLE: 'usertaskrole',
  CHAT: 'chat',
  TASK_ATTACHMENTS: 'taskattachments',
})

export const GroupModels = Object.freeze({
  USER_GROUP: 'usergroup',
  USER_GROUP_ASSOCIATED: 'usergroupassociated',
})

export const PermissionRoleIDs = Object.freeze({
  CREATOR: 1,
  SUPERADMIN: 2,
  ADMIN: 3,
  VIEWER: 4,
  STANDARD: 5,
  EDITOR: 6,
})

export const TaskDetailFields = [
  TaskFields.DESCRIPTION,
  TaskFields.FLAG,
] as const

// Filter and parse the response from DB to get an array that will be used to check permissions.
export const parseTaskRolePermissions = (
  inputArray: TaskRolePermissionResponse[],
  model: 'task' | 'group',
): TaskRolePermissions => {
  const result: TaskRolePermissions = {}
  const models: string[] =
    model === 'task' ? Object.values(TaskModels) : Object.values(GroupModels)
  for (const item of inputArray) {
    result[item.role.id] = item.permissions
      .filter((permission) => models.includes(permission.model))
      .map(
        (permission) =>
          permission.model + '.' + toCamelCase(permission.permission.field),
      )
    // For internal use I need a special permission that indicates if the role allows the submission of the task form
    if (canSubmitTaskForm(result[item.role.id])) {
      result[item.role.id].push(TaskInternals.SUBMIT_TASK)
    }
    if (canSubmitGroupForm(result[item.role.id])) {
      result[item.role.id].push(TaskInternals.SUBMIT_GROUP)
    }
  }
  return result
}

// Check if a user with assigned roles has a specific permission
export const hasPermission = (
  permission: string,
  assigned_roles?: number[],
  taskRolePermissions?: TaskRolePermissions,
) => {
  if (!taskRolePermissions) {
    return false
  }
  for (const roleId of assigned_roles || []) {
    const rolePermissions = taskRolePermissions?.[roleId]
    if (rolePermissions && rolePermissions.indexOf(permission) !== -1) {
      return true
    }
  }
  return false
}

// Validates if the user can submit the task form based on existing permissions
const canSubmitTaskForm = (permissions: string[]) => {
  return isNonMutuallyExclusiveSet(permissions, Object.values(TaskFields))
}

// Validates if the user can submit the group form based on existing permissions
const canSubmitGroupForm = (permissions: string[]) => {
  return isNonMutuallyExclusiveSet(permissions, Object.values(GroupFields))
}

const isNonMutuallyExclusiveSet = (set1: string[], set2: string[]) => {
  for (const permission of set1) {
    if (set2.indexOf(permission) !== -1) {
      return true
    }
  }
  return false
}

// Sanitize payload before calling the PATCH /task endpoint
export const removeNotAllowedFields = (
  draft: TaskType,
  assigned_roles?: number[],
  taskRolePermissions?: TaskRolePermissions,
) => {
  const CHILDREN = 'children'
  for (const key in draft) {
    if (key === 'details') {
      for (const detailsKey in draft['details']) {
        const permissionField = findInList(detailsKey, [...TaskDetailFields])
        if (
          permissionField &&
          !hasPermission(permissionField, assigned_roles, taskRolePermissions)
        ) {
          delete draft[key as keyof TaskType]
        }
      }
    } else {
      const altKey = key === CHILDREN ? TaskActions.ADD_CHILDREN : key
      const permissionField = findInList(altKey, Object.values(TaskFields))
      if (
        permissionField &&
        !hasPermission(permissionField, assigned_roles, taskRolePermissions)
      ) {
        delete draft[key as keyof TaskType]
      }
    }
  }
}

const findInList = (field: string, fullNameList: string[]) => {
  return fullNameList.find(
    (fullName) => fullName === field || fullName.split('.')[1] === field,
  )
}

const toCamelCase = (inputString: string) => {
  return inputString.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
}

// Get a list of available roles to be assigned
export const parseRoleList = (inputArray: TaskRolePermissionResponse[]) => {
  return inputArray
    .filter((role) => role.role.id !== PermissionRoleIDs.CREATOR)
    .map((role) => role.role)
}

export const getAssignedRolesPerGroup = (
  group?: TaskGroup,
  userId?: string,
) => {
  const assignedRoles: number[] = []
  group?.groupMembers
    ?.filter((groupMember) => groupMember.user === userId)
    .forEach((member) => {
      assignedRoles.push(...member.rolesData.map((itm) => itm.id))
    })
  return assignedRoles
}
