import { App, Popover, Row } from 'antd'
import clsx from 'clsx'
import { colorList, iconList } from 'components/AccountGroupFilter/GroupForm'
import DateSelectorModal from 'components/DateSelectorModal'
import GroupDropdown from 'components/GroupDropdown/GroupDropdown'
import StatusDropdown from 'components/StatusDropdown'
import { motion } from 'framer-motion'
import useStore from 'hooks/useStore'
import { decode } from 'html-entities'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import parse, { domToReact } from 'html-react-parser';

import { useTaskEditor } from 'sections/TaskEditorModal'
import { TaskMode, TaskType } from 'services/Tasks.slice'
import {
  TaskItem,
  TaskItemWithCuriculumId,
  TaskPriority,
  TaskStatusId,
} from 'types/Tasks'
import { TaskFields, hasPermission } from 'utils/permissions'
import {
  extractChecklistsWithCheckedBoxes,
  getCheckListItemsCount,
} from 'utils/richText'
import {
  convertTaskStatusToCurriculumStatus,
  getDateFromTitle,
  getGroupNameWithOwnerIfNeeded,
  isEmailIncludedInList,
  isInstanceBeforeToday,
  isJson,
} from 'utils/taskUtils'
import PlanningToolbar from './PlanningToolbar'
import styles from './TaskListItem.module.scss'
import TaskToolbar from './TaskToolbar'
import CompletedTaskToolbar from './CompletedTaskToolbar'

type TaskProps = {
  data: TaskItemWithCuriculumId
  index: number
  isExpanded?: boolean
  isReadonly?: boolean
  isDragging?: boolean
  hideStatus?: boolean
  isCurriculumTask?: boolean
  isCurriculumView?: boolean
  onViewTaskEditor?: (task: TaskItem) => void
  onViewFlowViewer?: (task: TaskItem) => void
  showChatIcon?: boolean
  onToggle?: (taskId: string) => void
  onDismiss?: (task: TaskItem) => void
}

export default function TaskListItem({
  data,
  isExpanded,
  isReadonly,
  isDragging,
  hideStatus,
  isCurriculumTask,
  isCurriculumView,
  onViewTaskEditor,
  onViewFlowViewer,
  showChatIcon,
  onToggle,
  onDismiss,
}: Readonly<TaskProps>) {
  const { t } = useTranslation()
  const app = App.useApp()
  const { getTaskById } = useStore((state) => state)
  const [color, setColor] = useState(colorList[0])
  const [icon, setIcon] = useState(iconList[0])
  const [minDate, setMinDate] = useState('')
  const [isParentLoading, setIsParentLoading] = useState(false)
  const [showDateSelector, setShowDateSelector] = useState(false)
  const [modalPromiseResolve, setModalPromiseResolve] = useState<
    ((value: string | PromiseLike<string>) => void) | null
  >(null)

  const { open, openCurriculumModal } = useTaskEditor()
  const selectedStatus = data?.status?.id
  const flagged = data?.taskDetails?.flag?.id === TaskPriority.URGENT
  const isCompletedTask = data?.status?.id === TaskStatusId.DONE
  const {
    selectedCurriculumTab,
    setShouldUpdateTasks,
    taskRolePermissions,
    tasks,
    updateCurriculumLesson,
    updateTask,
    user,
  } = useStore((state) => state)
  const { groups } = tasks
  const title = data?.title

  const checkListItems = getCheckListItemsCount(
    data?.taskDetails?.description || '',
  )

  const htmlChecklist = extractChecklistsWithCheckedBoxes(
    data?.taskDetails?.description || '',
  )

  const htmlChecklistChecked = htmlChecklist.filter(
    (item) => item.checked === 'true',
  )

  const { done, count }: { done?: number; count?: number } =
    htmlChecklist.length > 0
      ? { done: htmlChecklistChecked.length, count: htmlChecklist.length }
      : checkListItems || { done: undefined, count: undefined }
  const completedItemsMessage = count && ` ${done} of ${count} items completed`
  const curriculumInstanceId = data.curriculumTaskInstance?.id
  // Cannot reassign the group of a curriculum lesson outside of the curriculum
  const isCurriculumLesson = !!curriculumInstanceId

  // Used in Planning page
  const showAdditionalLine = hideStatus && completedItemsMessage

  const isDelegatedToMe =
    data.user?.id !== user?.data.id &&
    isEmailIncludedInList(data?.taskAssignees, user?.data.email)

  const canEditGroup = useMemo(() => {
    return hasPermission(TaskFields.GROUP, data?.roles, taskRolePermissions)
  }, [data?.roles, taskRolePermissions])
  const canEditStatus = useMemo(() => {
    return hasPermission(TaskFields.STATUS, data?.roles, taskRolePermissions)
  }, [data?.roles, taskRolePermissions])

  useEffect(() => {
    const { color, icon } = data.group?.metadata as {
      icon: string
      color: string
    }

    if (color) setColor(color)
    const iconTag = iconList.find((iconTag) => iconTag.value === icon)
    if (iconTag) setIcon(iconTag)
  }, [data.group])

  const getUserDateInput = () => {
    setShowDateSelector(true)
    return new Promise<string>((resolve) => {
      setModalPromiseResolve(() => resolve)
    })
  }

  const handleStatusChange = async (newStatus: TaskStatusId) => {
    const isCompletedSelected = newStatus === TaskStatusId.DONE

    let updatedProperties: TaskType = {
      status: newStatus,
    }

    if (isCompletedSelected) {
      if (data.isCustomValueRequired) {
        app.notification.warning({
          message: t('my-today.custom-value-required', {
            ns: 'validation',
          }),
        })
        return
      }

      updatedProperties.completionAt = new Date().toISOString()
      updatedProperties.mode = TaskMode.COMPLETED
      const isDateDifferent = isInstanceBeforeToday(data.title)
      if (curriculumInstanceId && isDateDifferent) {
        const minDate = getDateFromTitle(data.title)
        if (minDate) {
          setMinDate(minDate)
          const dateInput = await getUserDateInput()
          updatedProperties.completionAt = dateInput
        }
      }
    }
    updateTask(
      {
        taskId: data.id,
        data: updatedProperties,
      },
      user!.data.id,
    ).then(() => {
      const curriculumStatus = convertTaskStatusToCurriculumStatus(newStatus)
      if (curriculumInstanceId && curriculumStatus) {
        updateCurriculumLesson(curriculumInstanceId, {
          status: curriculumStatus,
        })
      }
    })
  }

  const handleTaskClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (isCurriculumTask) {
      openCurriculumModal(
        {
          ...data,
          isUpdate: true,
          isLibraryView: selectedCurriculumTab === 'library',
        },
        () => setShouldUpdateTasks,
      )
    } else {
      open(data, () => setShouldUpdateTasks(true))
    }
  }

  const handleParentClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setIsParentLoading(true)
    getTaskById(data.parent?.id as string).then((task) => {
      open(task, () => setShouldUpdateTasks(true))
    }).finally(() => {
      setIsParentLoading(false)
    })
  }

  const handleGroupChange = async (value: number = 0) => {
    const updatedProperties = {
      group: value,
    }
    await updateTask(
      {
        taskId: data.id,
        data: updatedProperties,
      },
      user!.data.id,
    )
  }

  const isPending = useMemo(
    () => isEmailIncludedInList(data.pendingAssignees, user?.data.email),
    [data.pendingAssignees, user],
  )

  return (
    <div>
      {data?.parent?.title &&
        <button
          className={isParentLoading ? styles.loadingButton : ''}
          style={{
            width: `calc(100% - ${isExpanded ? 32 : 6}px)`,
            display: 'block',
            border: 'none',
            color: 'white',
            textAlign: 'left',
            backgroundColor: color,
            opacity: .7,
            marginLeft: isExpanded ? 32 : 6,
            marginBottom: -4,
            borderRadius: "4px 4px 0 0",
            cursor: 'pointer',
            paddingLeft: 8,
            paddingBottom: 4,
          }}
          onClick={handleParentClick}
        >
          <span className={styles.parentTask}>{data?.parent?.title}</span>
        </button>
      }
      <motion.div
        layout={isExpanded}
        initial={{ height: '40px' }}
        animate={{
          height: isExpanded ? 'auto' : showAdditionalLine ? '60px' : '40px',
        }}
        transition={{
          type: 'spring',
          damping: 20,
          stiffness: 300,
        }}
        onClick={handleTaskClick}
        className={
          clsx(
            styles.taskItemWrapper,
            isExpanded && styles.taskItemWrapperExpanded,
            isCompletedTask && styles.taskCompleted,
            isDragging && styles.taskDragging,
          ) + ' task-list-item'
        }
        style={{ zIndex: 1, position: 'relative' }}
      >
        <div
          className={clsx(
            styles.categoryIconWrapper,
            isExpanded && styles.categoryIconWrapperExpanded,
          )}
          style={{ backgroundColor: color }}
        >
          {isCurriculumView && (
            <Popover
              content={t('curriculum.change-task-group')}
              placement="left"
            >
              <div onClick={(e) => e.stopPropagation()}>
                <GroupDropdown
                  onChange={handleGroupChange}
                  icon={icon.label}
                  isReadonly={isReadonly || !canEditGroup}
                  isDisabled={isDelegatedToMe || isCurriculumLesson}
                  groupName={getGroupNameWithOwnerIfNeeded(data, groups)}
                />
              </div>
            </Popover>
          )}
          {!isCurriculumView && (
            <div onClick={(e) => e.stopPropagation()}>
              <GroupDropdown
                onChange={handleGroupChange}
                icon={icon.label}
                isReadonly={isReadonly || !canEditGroup}
                isDisabled={isDelegatedToMe || isCurriculumLesson}
                groupName={getGroupNameWithOwnerIfNeeded(data, groups)}
              />
            </div>
          )}
        </div>
        <div className={styles.contentWrapper + ' task-list-item-content'}>
          <Row style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {!hideStatus && (
              <StatusDropdown
                value={selectedStatus}
                style={{
                  width: 115,
                  margin: 'auto',
                  marginRight: 8,
                  opacity: isCompletedTask ? 0.8 : 1,
                }}
                onChange={handleStatusChange}
                isReadonly={isReadonly || !canEditStatus}
              />
            )}
            <span
              className={
                clsx(styles.taskTitle, isCompletedTask && styles.crossedOut) +
                ' task-list-item-content-title'
              }
            >
              {title}
              {showAdditionalLine && (
                <>
                  <br />
                  {completedItemsMessage}
                </>
              )}
            </span>
            {!showAdditionalLine && completedItemsMessage && (
              <span style={{ paddingLeft: 4 }}>{completedItemsMessage}</span>
            )}
            {onViewFlowViewer && (
              <PlanningToolbar
                task={data}
                onViewTaskEditor={() => onViewTaskEditor?.(data)}
                onViewFlowViewer={() => onViewFlowViewer(data)}
              />
            )}
            {!onViewFlowViewer &&
              !isCompletedTask &&
              !isCurriculumTask &&
              !isPending && (
                <TaskToolbar
                  task={data}
                  flagged={flagged}
                  isTaskExpanded={isExpanded}
                  isReadonly={isReadonly}
                  showChatIcon={showChatIcon}
                  onToggle={onToggle}
                />
              )
            }
            {!onViewFlowViewer &&
              onDismiss &&
              isCompletedTask &&
              !isCurriculumTask && (
                <CompletedTaskToolbar task={data} onDismiss={onDismiss} />
              )
            }
          </Row>
          {isExpanded &&
            data.taskDetails?.description &&
            isJson(data.taskDetails?.description || '') && (
              <div>
                {
                  parse(
                    JSON.parse(data.taskDetails?.description).root || '',
                    {
                      replace: (domNode) => {
                        if (domNode.type === 'tag' && domNode.name === 'a') {
                          domNode.attribs.target = '_blank'
                          return <span onClick={(e) => e.stopPropagation()}>{domToReact([domNode])}</span>
                        }
                      }
                    }
                  )
                }
              </div>
            )
          }
          {isExpanded &&
            data.taskDetails?.description &&
            !isJson(data.taskDetails?.description || '') && (
              <div>
                {
                  parse(decode(data.taskDetails?.description || ''),
                    {
                      replace: (domNode) => {
                        if (domNode.type === 'tag' && domNode.name === 'a') {
                          domNode.attribs.target = '_blank'
                          return <span onClick={(e) => e.stopPropagation()}>{domToReact([domNode])}</span>
                        }
                      }
                    }
                  )
                }
              </div>
            )
          }
        </div>
      </motion.div>
      <DateSelectorModal
        isOpen={showDateSelector}
        onClose={() => setShowDateSelector(false)}
        onResolve={modalPromiseResolve}
        min={minDate}
      />
    </div>
  )
}
