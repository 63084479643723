import { useMemo } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Cell, Pie, PieChart } from 'recharts'
import { DelegationType, TaskItem } from 'types/Tasks'

const COLORS = [
  'var(--not-started-color)',
  'var(--in-progress-active-color)',
  'var(--in-progress-paused-color)',
  'var(--completed-color)',
]

type DelegatedTaskHeatmapProps = {
  tasks?: TaskItem[]
}

const DelegatedTaskHeatmap = ({ tasks }: DelegatedTaskHeatmapProps) => {
  const { t } = useTranslation()
  const data = useMemo(() => {
    const taskCounts: Record<string, number> = {
      'Not Started': 0,
      'Active': 0,
      'Paused': 0,
      'Completed': 0,
    }
    tasks?.forEach(task => {
      if (task.delegationType === DelegationType.INDIVIDUAL_PARENT && task.children) {
        task.children.forEach(child => {
          taskCounts[child.status?.title || 'Not Started']++
        })
      } else {
        taskCounts[task.status?.title || 'Not Started']++
      } 
    })
    return Object.entries(taskCounts).map(([name, value]) => ({ name, value }))
  }, [tasks])
  
  return (
    <Tooltip title={t('my-today-page.task-status')}>
      <PieChart width={20} height={20}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={10}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </Tooltip>
  )
}

export default DelegatedTaskHeatmap
