import { ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import UserAvatar from 'components/UserAvatar'

type EmailListItemProps = {
  email: string
  image: string
  name?: string
  status?: string
  style?: React.CSSProperties
}

const EmailListItem = ({
  name,
  email,
  image,
  status,
  style,
}: EmailListItemProps) => (
  <ListItem style={style}>
    <ListItemAvatar>
      <UserAvatar
        image={image}
        size="large"
        label={name?.trim() || email}
        delegationStatus={status}
      />
    </ListItemAvatar>
    <ListItemText
      primary={name?.trim() || email}
      primaryTypographyProps={{ variant: 'subtitle2' }}
      secondary={email}
      secondaryTypographyProps={{
        color: 'var(--text-color)',
      }}
    />
  </ListItem>
)
export default EmailListItem
