import React, { useEffect } from 'react'
import { useWindowSize } from 'react-use'
import useStore from 'hooks/useStore'
import Confetti from 'react-confetti'

const ConfettiComponent = () => {
  const { showConfetti, setShowConfetti } = useStore(state => state)
  const { width, height } = useWindowSize()

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false)
      }, 10000)
    }
  }, [showConfetti, setShowConfetti])

  return (
    <>
    {showConfetti && <Confetti
        width={width}
        height={height}
        recycle={false}
      />}
    </>
  )
}

export default ConfettiComponent
