import * as React from 'react'
import type { PendingAssignee, TaskAssignees } from 'types/Tasks'
import { useMemo } from 'react'
import { AssigneeInvitationStatus } from 'utils/taskUtils'
import UserAvatar from './UserAvatar'
import { Space, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

type UserAvatarGroupProps = {
  pendingAssignees?: PendingAssignee[]
  taskAssignees?: TaskAssignees[]
  size?: number
  maxCount?: number
}

export const stringAvatar = (label: string): string | null => {
  const initials = label
    .split(' ')
    .map((part) => part.charAt(0))
    .slice(0, 3)
    .join('')
    .toUpperCase()
  return initials
}

const UserAvatarGroup = ({
  pendingAssignees,
  taskAssignees,
  size = 24,
  maxCount = 2,
}: UserAvatarGroupProps) => {
  const { t } = useTranslation()

  const userList = useMemo(
    () =>
      (taskAssignees || [])
        .map(({ assigneeName, avatar }) => ({
          assigneeName,
          avatar,
          status: AssigneeInvitationStatus.ACCEPTED as string,
          createdAt: '',
        }))
        .concat(
          (pendingAssignees || []).map(
            ({ email, avatar, status, createdAt }) => ({
              assigneeName: email,
              avatar,
              status,
              createdAt: createdAt || '',
            }),
          ),
        ),
    [pendingAssignees, taskAssignees],
  )

  const remainingUsers = userList.slice(maxCount, userList.length)

  if ((taskAssignees || []).length + (pendingAssignees || []).length === 0) {
    return null
  }

  return (
    <Space
      size={0}
      style={{
        marginRight: 16,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {userList.slice(0, maxCount).map((viewer, index) => (
        <Tooltip
          key={index}
          title={t('my-today-page.delegated-to', {
            ns: 'common',
            name: viewer.assigneeName,
            status: viewer.status,
          })}
          overlayStyle={{ whiteSpace: 'pre-wrap' }}
        >
          <UserAvatar
            label={viewer.assigneeName}
            image={viewer.avatar}
            size={size}
            delegationStatus={viewer.status}
            avatarStyles={{
              marginRight: -12,
            }}
          />
        </Tooltip>
      ))}
      {userList.length > maxCount && (
        <Tooltip
          title={() => {
            return (
              <Space
                size={0}
                style={{
                  marginRight: 12,
                  backgroundColor: 'white',
                }}
              >
                {remainingUsers.map((viewer, index) => (
                  <Tooltip
                    key={index}
                    title={t('my-today-page.delegated-to', {
                      ns: 'common',
                      name: viewer.assigneeName,
                      status: viewer.status,
                    })}
                  >
                    <UserAvatar
                      label={viewer.assigneeName}
                      image={viewer.avatar}
                      size={size}
                      delegationStatus={viewer.status}
                      avatarStyles={{
                        marginRight: -12,
                      }}
                    />
                  </Tooltip>
                ))}
              </Space>
            )
          }}
          arrow={false}
          color="white"
          placement="top"
        >
          <UserAvatar
            label={`+ ${userList.length - maxCount}`}
            size={size}
            avatarStyles={{
              marginRight: -12,
            }}
          />
        </Tooltip>
      )}
    </Space>
  )
}

export default UserAvatarGroup
